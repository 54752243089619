<template>

  <Card :title="title">

    <div class="form">
      <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :colon="false">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="管道编号">
              <a-input v-decorator="[
                'pipeCode',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="管道类型">
              <a-select v-decorator="[
                'pipeType',
                {
                  rules: [
                    {
                      //required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]">
                <a-select-option v-for="item in [{
                  name: '污水',
                  value: 'sewage',
                }, {
                  name: '雨水',
                  value: 'rain'
                }]" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="管径(mm)">
              <a-input-number style="width: 100%" v-decorator="[
                'diameter',
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="管长(m)">
              <a-input-number style="width: 100%" v-decorator="[
                'length',
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所在道路">
              <a-input v-decorator="[
                'location',
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="材质">
              <a-input v-decorator="[
                'material',
              ]" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="管道起点">
              <a-input v-decorator="[
                'pointFrom',
              ]" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="管道终点">
              <a-input v-decorator="[
                'pointTo',
              ]" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="起点埋深">
              <a-input-number style="width: 100%" v-decorator="[
                'buringDepthFrom',
              ]" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="终点埋深">
              <a-input-number style="width: 100%" v-decorator="[
                'buringDepthTo',
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="检测状态">
              <a-select v-decorator="[
                'result',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]">
                <a-select-option v-for="item in [{
                  name: '正常',
                  value: '1',
                }, {
                  name: '异常',
                  value: '0'
                }]" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="检测日期">
              <a-date-picker style="width: 100%" placeholder v-decorator="[
                'time',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="检测详情" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">

              <a-textarea :auto-size="{ minRows: 3 }" v-decorator="[
                'content',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]" placeholder="" />

            </a-form-item>
          </a-col>


          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属管道检测任务">
              <a-select v-decorator="[
                'pid',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]">
                <a-select-option v-for="item in tasks" :key="item.id" :value="item.id">{{ item.name
                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="检测视频">
              <input type="file" ref="upload" @change="onChange" style="display: none" />
              <a-button type="primary" @click="onUpload" :loading="uploading">
                {{ file ? '已上传' + file.name : '上传' }}
              </a-button>
            </a-form-item>
          </a-col>

        </a-row>

        <div class="center" style="margin-top: 24px;">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button html-type="submit" type="primary" :loading="loading">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </Card>

</template>

<script>
import { addResult, editResult, fetchList, fetchResultDetail } from "../api";
import { upload } from "@/api/upload";
import moment from 'moment'
export default {

  data() {
    return {
      id: '',
      title: '新建管道检测结果',
      form: this.$form.createForm(this),
      loading: false,

      tasks: [],

      file: null,
      uploading: false,
    };
  },

  mounted() {

    const { id } = this.$route.query;
    if (id) {
      this.id = id;
      this.title = '编辑管道检测结果'

      fetchResultDetail({ id }).then(res => {
        if (!res) return;

        this.form.setFieldsValue({
          pipeCode: res.pipeCode,
          pipeType: res.pipeType,
          diameter: res.diameter,
          length: res.length,
          location: res.location,
          material: res.material,
          pointFrom: res.pointFrom,
          pointTo: res.pointTo,
          buringDepthFrom: res.buringDepthFrom,
          buringDepthTo: res.buringDepthTo,
          result: String(res.result),
          time: res.time ? moment(res.time) : undefined,
          content: res.content,
          pid: res.pid,

        })

        this.file = res.videoVo ? res.videoVo : null;

      });

    }
    fetchList({
      pageNum: 1,
      pageSize: 99,
    }).then(res => {
      if (Array.isArray(res.list)) {
        this.tasks = res.list;
      }
    })
  },


  methods: {

    onUpload() {
      this.$refs.upload.click();
    },
    onChange(e) {
      const files = e.target.files;
      if (files.length === 0) {
        return;
      }

      const data = new FormData();
      data.append("files", files[0]);

      this.uploading = true;

      upload(data)
        .then((res) => {
          console.log("upload", res);
          if (Array.isArray(res)) {
            this.file = res[0]
          }
        })
        .finally(() => {
          this.uploading = false;
          e.target.value = null;
        });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...values,
            time: values.time.format("YYYY-MM-DD"),
            video: this.file ? this.file.id : undefined
          };

          console.log(params);

          this.loading = true;

          let task = addResult;
          if (this.id) {
            task = editResult
          }
          task({
            id: this.id ? this.id : undefined,
            ...params,
          }).then(() => {
            this.cancel();
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped></style>