var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":_vm.title}},[_c('div',{staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"管道编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pipeCode',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'pipeCode',\n              {\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"管道类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pipeType',
              {
                rules: [
                  {
                    //required: true,
                    message: '请选择',
                  },
                ],
              },
            ]),expression:"[\n              'pipeType',\n              {\n                rules: [\n                  {\n                    //required: true,\n                    message: '请选择',\n                  },\n                ],\n              },\n            ]"}]},_vm._l(([{
                name: '污水',
                value: 'sewage',
              }, {
                name: '雨水',
                value: 'rain'
              }]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"管径(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'diameter',
            ]),expression:"[\n              'diameter',\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"管长(m)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'length',
            ]),expression:"[\n              'length',\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"所在道路"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'location',
            ]),expression:"[\n              'location',\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"材质"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'material',
            ]),expression:"[\n              'material',\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"管道起点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pointFrom',
            ]),expression:"[\n              'pointFrom',\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"管道终点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pointTo',
            ]),expression:"[\n              'pointTo',\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"起点埋深"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'buringDepthFrom',
            ]),expression:"[\n              'buringDepthFrom',\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"终点埋深"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'buringDepthTo',
            ]),expression:"[\n              'buringDepthTo',\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检测状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'result',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]),expression:"[\n              'result',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择',\n                  },\n                ],\n              },\n            ]"}]},_vm._l(([{
                name: '正常',
                value: '1',
              }, {
                name: '异常',
                value: '0'
              }]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检测日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'time',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'time',\n              {\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"检测详情","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'content',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'content',\n              {\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 3 },"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"所属管道检测任务"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pid',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]),expression:"[\n              'pid',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.tasks),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检测视频"}},[_c('input',{ref:"upload",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onChange}}),_c('a-button',{attrs:{"type":"primary","loading":_vm.uploading},on:{"click":_vm.onUpload}},[_vm._v(" "+_vm._s(_vm.file ? '已上传' + _vm.file.name : '上传')+" ")])],1)],1)],1),_c('div',{staticClass:"center",staticStyle:{"margin-top":"24px"}},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"html-type":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }